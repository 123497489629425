.custom-tabs {
  border: none;
  margin-bottom: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 15px;
  position: relative;
}

/* Common styles for both buttons */
.custom-tab-button.nav-link {
  font-weight: 500;
  font-size: 16px;
  padding: 5px 25px;
  border-radius: 50px;
  margin: 0 10px;
  transition: 0.3s;
  position: relative;
  z-index: 1;
}

/* Styles for Córdoba (IPJ) button */
.custom-tab-button.nav-link[type="button"]:nth-of-type(1) {
  color: #db1414;
  border: 2px solid #db1414;
  background: transparent;
}

.custom-tab-button.nav-link[type="button"]:nth-of-type(1):hover,
.custom-tab-button.nav-link[type="button"]:nth-of-type(1).active {
  color: #fff;
  background: #db1414;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(219, 20, 20, 0.3);
}

/* Styles for CABA (IGJ) button */
.custom-tab-button.nav-link[type="button"]:nth-of-type(2) {
  color: #00e658;
  border: 2px solid #00ea0c;
  background: transparent;
}

.custom-tab-button.nav-link[type="button"]:nth-of-type(2):hover,
.custom-tab-button.nav-link[type="button"]:nth-of-type(2).active {
  color: #fff;
  background: #00e658;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 230, 88, 0.3);
}

/* Active tab container styling */
.tab-content {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  transition: 0.3s;
}

.tab-pane.active {
  animation: fadeIn 0.5s ease-in;
}

.tab-pane.active::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: -1;
}

/* Animation for tab content */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Active indicator dot */
.custom-tab-button.nav-link.active::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: currentColor;
}

/* Highlight location style */
.highlight-location {
  background-color: #db1414;
  color: white;
  padding: 3px 10px;
  border-radius: 0px;
  margin: 0 2px;
  display: inline-block;
  line-height: 1.2;
}

.pricing-item.featured {
  position: relative;
}

.most-popular-tag {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #db1414;
  color: white;
  padding: 5px 15px;
  border-radius: 25px;
  font-size: 0.85rem;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  transform: rotate(15deg);
  z-index: 1;
}

.about-us {
  background-color: #f8f9fa;
  padding: 80px 0;
}

.about-logo {
  height: 25px;
  width: auto;
  vertical-align: middle;
  margin-right: 3px;
  display: inline-block;
}

.grupomw-logo {
  height: 30px;
  width: auto;
  vertical-align: middle;
  margin: 0 3px;
  display: inline-block;
}

.about-us .description {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #444444;
  margin-top: 30px;
  text-align: justify;
  padding: 0 20px;
}

.about-us .highlight {
  color: #db1414;
  font-weight: 600;
  white-space: nowrap;
}

.about-us .rocket-emoji {
  font-size: 1.5rem;
  margin-left: 8px;
  display: inline-block;
  animation: float 3s ease-in-out infinite;
  vertical-align: middle;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.about-content {
  background: white;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 10px 15px -10px rgba(0, 0, 0, 0.1),
              0 -10px 15px -10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
} 